import { type DeployConfigFrontend } from "library-frontend-utils/infra";

const config: DeployConfigFrontend = {
  local: {
    authenticationApp: "t60DeveloperPortal",
    backendUrl: "http://localhost:3000",
  },
  t60dev: {
    authenticationApp: "t60DeveloperPortal",
    backendUrl: "http://localhost:3000",
  },
  t60prod: false,
  varenergidev: false,
  varenergitest: false,
  varenergiprod: false,
};

export default config;
