import { createBrowserRouter, redirect } from "react-router-dom";
import type { StackName } from "../config";
import { stackNames } from "../config";
import { ErrorPage } from "library-ui-components/pages";
import HttpResponseError from "library-ui-components/pages/error/HttpResponseError";

export const appRoutes: Parameters<typeof createBrowserRouter>[0] = [
  {
    path: "/",
    async lazy() {
      const c = await import("./root");
      return { Component: c.default };
    },
    loader: ({ params: { stackName } }) => {
      if (stackName) {
        if (!stackNames.includes(stackName as StackName))
          throw new HttpResponseError(404);
        return null;
      } else return redirect(`/${stackNames[0]}`);
    },
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: ":stackName",
        async lazy() {
          const c = await import("./main");
          return { Component: c.default };
        },
      },
      {
        path: ":stackName/info",
        async lazy() {
          const c = await import("./info");
          return { Component: c.default };
        },
      },
      {
        path: ":stackName/repo",
        async lazy() {
          const c = await import("./repo");
          return { Component: c.default };
        },
      },
      {
        path: "features",
        loader: () => {
          return redirect(`/${stackNames[0]}`);
        },
      },
    ],
  },
];

export const router = createBrowserRouter(appRoutes);
